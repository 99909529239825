<!-- eslint-disable -->
<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
  <b-modal v-model="shownProfile"  hide-footer size="lg">
    <template #modal-title>
      Gestion de profils
    </template>
          <v-container class="py-0">
            <b-form @submit.prevent="updateProfile">
                <v-row>
          <v-col cols="12" md="6">
            <v-text-field required v-model="provider.email" label="Email" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field required v-model="provider.password" label="Mot de passe" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field required v-model="provider.name" label="Nom" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field required v-model="provider.phone" placeholder="phone1/phone2" label="N° Téléphone" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="6">
            <label>Wilaya</label>
            <b-form-select required v-model="selectedWilaya" @change="provider.wilaya = selectedWilaya.name">
              <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select>
          </v-col>
          <v-col cols="12" md="6">
            <label>Commune</label>
            <b-form-select required v-model="selectedCommunue" @change="provider.communue = selectedCommunue">
              <option v-for="communue in selectedWilaya.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
            </b-form-select>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field required v-model="provider.address" label="Adresse" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="12" class="text-right">
            <v-btn type="submit" style="background-color:#4caf50;float:right" class="mr-0">
              Confirmer
            </v-btn>
          </v-col>
          </v-row>
        </b-form>
        </v-container>
  </b-modal>
  <b-modal v-model="shownPayment"  hide-footer size="lg">
    <template #modal-title>
      Gestion des paiements
    </template>
          <v-container class="py-0">
            <b-form @submit.prevent="addPaymentMethod">
          <div>
          <v-row>
          <v-col cols="12" md="12">
            <label>Type de paiement</label>
            <br>
              <b-form-select style="width:80%" required v-model="payment_method.type">
                <option v-for="method in ['CCP','Banque','Paysera','Autres']" :key="method" :value="method">{{method}}</option>
              </b-form-select>
              <v-btn type="submit" style="background-color:#4caf50;float:right">Ajouter</v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field required v-model="payment_method.name" label="Nom" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field required v-model="payment_method.number" label='N° de compte' class="purple-input"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field required v-model="payment_method.bank" label="Banque" class="purple-input"/>
          </v-col>
          </v-row>
          </div>
          <v-row v-for="(payment,index) in provider.payment_methods" :key="payment.type">
            <v-col cols="12" md="3">
              <strong>Type: </strong>{{payment.type}}
            </v-col>
            <v-col cols="12" md="3">
              <strong>Nom: </strong>{{payment.name}}
            </v-col>
            <v-col cols="12" md="3">
              <strong>N° de compte </strong>{{payment.number}}
            </v-col>
            <v-col cols="12" md="3">
              <strong>Nom de banque: </strong>{{payment.bank}}
              <b-button-close @click="removePaymentMethod(index)"/>
            </v-col>
            <hr style="width:100%">
          </v-row>
          <v-col cols="12" md="12" class="text-right">
            <v-btn @click="updatePaymentMethods" style="background-color:#4caf50;float:right" class="mr-0">
              Confirmer
            </v-btn>
          </v-col>
        </b-form>
        </v-container>
  </b-modal>
  <b-modal v-model="shownTerms"  hide-footer size="lg">
    <template #modal-title>
      Conditions d'utilisation
    </template>
    <v-container class="py-0" style="overflow-y:scroll;height:600px">
      <div v-for="term in terms" :key="term.title">
        <h2>{{term.title}}</h2>
        <p>{{term.content}}</p>
        <hr style="width:100%">
      </div>
    </v-container>
  </b-modal>
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />
    <!-- this one here is for the search bar you will need it later it goes under v-text-field  -->
    <!-- <v-text-field
      :label="$t('Search')"
      color="secondary"
      hide-details
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn> -->

    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->
<v-btn v-if="provider.type == 'driver'" @click="showQrCode" text>
  <v-icon style="">mdi-qrcode</v-icon>
</v-btn>
<v-btn @click="clearNotification" text>
  <span v-if="newMessage != null">Nouvelle Demande</span>
  <v-icon v-if="newMessage == null" style="">mdi-bell</v-icon>
  <v-icon v-else style="color:red">mdi-bell-badge</v-icon>
</v-btn>
<v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="checkAdmin"
      v-bind="attrs"
      v-on="on"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn>
      </template>
      <v-list :tile="false" nav>
        <div>
          <div @click="showProfile" v-if="provider.type == 'provider' || provider.type == 'post_center' || provider.type == 'logistic'">
          <app-bar-item>
            <v-icon v-if="newMessage == null" style="">mdi-account</v-icon>
            <v-list-item-title v-text="'Profile'" style="margin-left:10px"/>
          </app-bar-item>
          </div>
          <div @click="showWallet" v-if="provider.type == 'provider' || provider.type == 'post_center' || provider.type == 'logistic'">
          <app-bar-item> 
            <v-icon v-if="newMessage == null" style="">mdi-wallet</v-icon>
            <v-list-item-title v-text="'Portfeuille'" style="margin-left:10px"/>
          </app-bar-item>
          </div>
          <div @click="showPayment" v-if="provider.type == 'provider' || provider.type == 'post_center' || provider.type == 'logistic'">
          <app-bar-item >
            <v-icon v-if="newMessage == null" style="">mdi-credit-card-multiple</v-icon>
            <v-list-item-title v-text="'Méthodes de paiement'" style="margin-left:10px"/>
          </app-bar-item>
          </div>
          <div @click="showTerms" v-if="provider.type == 'provider' || provider.type == 'post_center' || provider.type == 'logistic'">
          <app-bar-item>
            <v-icon v-if="newMessage == null" style="">mdi-information</v-icon>
            <v-list-item-title v-text="'Conditions d\'utilisation'" style="margin-left:10px"/>
          </app-bar-item>
          </div>
          <div @click="signOut">
          <app-bar-item >
            <v-icon v-if="newMessage == null" style="">mdi-logout</v-icon>
            <v-list-item-title v-text="'Se Déconnecter'" />
          </app-bar-item>
          </div>
        </div>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
/* eslint-disable */
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
  import axios from 'axios'
  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',
    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        terms: [
          {'title':'Overview','content':'The website and the solutions are operated and managed by separate entities. Refsnes Data offers the website (w3schools.com) including all its content, information, and related tools. The solutions (Such as Spaces, Courses, and My learning) and billing system are operated by W3schools Network. The two entities are separately responsible for the services that they provide, manage and operate.Throughout the site, the terms "we", "us" and "our" refer to Refsnes Data and W3schools Network.By visiting our site and/or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions ("Terms of Service", "Terms"), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.Please read these Terms of Service carefully before accessing or using our website or services. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.Any new features or tools which are added to the current site shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.'},
          {'title':'Site Usage Terms','content':"By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site. Access to services may require you to register an account. This could require you to provide information about yourself such as name, email address and credit card details. The account information and the use of data is subject to our Privacy Policy (https://www.w3schools.com/about/about_privacy.asp) You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). You are responsible for complying with any law, rules, and regulations applicable to you. You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of our Terms can result in an immediate termination of your Services. We reserve the right to remove or restrict your account and to remove content such as websites and other user-created data at any time at our sole and absolute discretion."},
          {'title':'General Conditions','content':"We reserve the right to refuse service to anyone for any reason at any time. You understand that your content may be transferred unencrypted and involve (a) transmissions over various networks, and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks, and are processed and handled by our selected Payment Service Providers. We only preserve the last four-digit and expiry date of your Credit card. This is, among other things, to let you know in advance if your Credit card is soon to expire, so that we can assure the continuous running of your Services with us. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us. The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms."},
        ],
        payment_method: {
          'type':'',
          'name':'',
          'number':'',
          'bank':''
        },
        provider:{},
        communues:[],
        selectedWilaya: {
          name:null,
          communues: []
        },
        selectedCommunue: null,
        wilayas:null,
        selectedVehicule: null,
        selectedMethod:null,
        wilaya: null,
        selectedMethod:null,
        shownTerms:false,
        shownPayment:false,
        shownWallet:false,
        shownProfile:false,
        userType:null,
        newMessage:null
      }
    },

    computed: {
      ...mapState(['drawer']),
    },
    mounted() {
      this.checkNewMessage()
      this.getWilayas()
      this.userType = localStorage.getItem('user_type')
    },
    methods: {
      updatePaymentMethods() {
        axios.post('update-payment-methods',{
          auth_token: localStorage.getItem('auth_token'),
          payment_methods : this.provider.payment_methods
        }).then(response => {
          this.shownPayment = false
        })
      },
      addPaymentMethod() {
        this.provider.payment_methods.push(this.payment_method)
        this.payment_method = {'type':'','name':'','number':'','bank':''}
      },
      removePaymentMethod(index) {
        this.provider.payment_methods.splice(index,1)
      },
      getWilayas() {
      axios.post('get-wilayas').then(response => {
          if (response.data.status == 'success') {
            this.wilayas = response.data.data
          }
          this.detectingprovider()
        })
      },
      detectingprovider(){
        axios.post('detectingprovider',{
          auth_token: localStorage.getItem('auth_token'),
        }).then(response => {
          this.provider = response.data.data.provider
          console.log(this.provider.payment_methods)
          if (this.provider.payment_methods == null) {
            this.provider.payment_methods = []
          }
          for (let i in this.wilayas) {
            if (this.wilayas[i].name == this.provider.wilaya) this.selectedWilaya = this.wilayas[i]
          }
          this.selectedCommunue = this.provider.communue
        })
      },
      updateProfile() {
        axios.post('admin-change-profile', {
        auth_token: localStorage.getItem('auth_token'),
        user_info: {
          auth_token: this.provider.auth_token,
          email:this.provider.email,
          password:this.provider.password,
          name: this.provider.name,
          phone: this.provider.phone,
          code: this.provider.code,
          wilaya:this.selectedWilaya.name,
          communue:this.selectedCommunue,
          address:this.provider.address,
          selectedVehicule: this.provider.selectedVehicule,
          selectedService: this.provider.selectedService,
          user_type: this.provider.type,
        }
      }).then(response => {
        this.shownProfile = false
      })
      },
      showTerms() {
        this.shownTerms = true
      },
      showPayment() {
        this.shownPayment = true
      },
      showWallet() {

      },
      showProfile() {
        this.shownProfile = true
      },
      checkNewMessage() {
        if (localStorage.getItem('newMessage') != null && localStorage.getItem('newMessage') != undefined) {
          this.newMessage = localStorage.getItem('newMessage')
        } else {
          this.newMessage = null
        }
        setTimeout(this.checkNewMessage, 2000)
      },
      showQrCode() {
        axios.post('make-driver-qr', {
          auth_token: localStorage.getItem('auth_token')
        }).then(response=>{
            const link = document.createElement('a')
            link.target = '_blank'
            link.href = process.env.VUE_APP_MEDIA_URL+"document.pdf"
            link.setAttribute('download', "document.pdf")
            document.body.appendChild(link)
            link.click()
        });
      },
      clearNotification() {
        if (this.newMessage != null) {
          this.$router.push({name:'AdminRequests'})
          localStorage.removeItem('newMessage')
          this.newMessage = null
        }
      },
      checkAdmin() {
        if (localStorage.getItem('admin_auth_token') != null && localStorage.getItem('admin_auth_token') != undefined) {
          localStorage.setItem('auth_token',localStorage.getItem('admin_auth_token'))
          localStorage.removeItem('admin_auth_token')
          window.location.replace("admin-dashboard");
        }
      },
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      signOut(params) {
        let auth_token = localStorage.getItem('auth_token')
        let token = localStorage.getItem('device_token')
        axios.post('add-new-driver-device', {
          auth_token: auth_token,
          token: token,
          input_type:'remove',
          user_type:'admin'
        })
        localStorage.clear()
        this.$router.push({name:'Home'})

      }
    },
  }
</script>
